<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card tile elevation="1">
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                План рахунків бухгалтерського обліку
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <MainModal
                  :main="{ component: 'ChartOfAccount', title: 'Рахунки бух.обліку' }"
                  :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
                  :item="selected_item"
                  :modal="dialog_id === selected_item.id"
                  @updateItemModal="updateItemModal"
              />
            </v-toolbar>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card elevation="1">
          <v-card-text class="px-3 py-3">
            <div class="table-header" style="display: flex;">
              <div class="table-col" style="flex: 0 0 90px; padding-left: 5px">
                Код
              </div>
              <div class="table-col" style="flex: 0 0 400px;">
                Найменування
              </div>
              <div class="table-col" style="flex: 0 0 60px;">
                Акт.
              </div>
              <div class="table-col" style="flex: 0 0 60px;">
                Кіл.
              </div>
              <div class="table-col" style="flex: 1;">
                Субконто 1
              </div>
              <div class="table-col" style="flex: 1;">
                Субконто 2
              </div>
              <div class="table-col" style="flex: 1;">
                Субконто 3
              </div>
            </div>
            <div class="table-body">
              <div class="table-row" style="display: flex; cursor: pointer"
                   @click="openChartModal(item)"
                   v-for="(item, idx) in items"
                   :key="idx"
              >
                <div class="table-col font-weight-bold" style="flex: 0 0 90px; padding-left: 5px">
                  {{ item.code }}
                </div>
                <div class="table-col" style="flex: 0 0 400px;">
                  {{ item.name }}
                </div>
                <div class="table-col font-weight-medium" style="flex: 0 0 60px;"
                     :class="getBalanceType(item.balance_type,false,true)">
                  {{ getBalanceType(item.balance_type, true, false) }}
                </div>
                <div class="table-col" style="flex: 0 0 60px;">
                  <v-simple-checkbox v-model="item.counted" disabled class="pa-0 ma-0"/>
                </div>
                <div class="table-col" style="flex: 1;">
                  {{ item.details.length > 0 ? getSubcontoType(item.details[0].detail_type) : ''  }}
                </div>
                <div class="table-col" style="flex: 1;">
                  {{ item.details.length > 1 ? getSubcontoType(item.details[1].detail_type) : ''  }}
                </div>
                <div class="table-col" style="flex: 1;">
                  {{ item.details.length > 2 ? getSubcontoType(item.details[2].detail_type) : ''  }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getSubcontoType, getBalanceType} from "@/utils/icons"
import {mapActions, mapGetters} from "vuex";
import {FETCH_CHART_OF_ACCOUNT} from "@/store/actions/accounting/chart_of_account";
export default {
  name: "chart_of_accounts",
  components: {
    MainModal: () => import('@/components/modal/MainModal')
  },
  computed: {
    ...mapGetters({
      items: 'getChartsOfAccount'
    })
  },
  data() {
    return {
      selected_item: {},
      dialog_id: 0
    }
  },
  methods: {
    ...mapActions({
      fetch_all: FETCH_CHART_OF_ACCOUNT
    }),
    getSubcontoType,
    getBalanceType,
    openChartModal(item) {
      let idx = 0
      const details = item.details.map(d => {
        idx += 1
        return Object.assign({}, d, {row_num: idx})
      })
      this.selected_item = Object.assign({}, item, {details: details})
      this.dialog_id = item.id
    },
    updateItemModal() {
      this.selected_item = {}
      this.dialog_id = 0
    }
  },
  created() {
    this.fetch_all()
  }
}
</script>

<style scoped lang="scss">
.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
}
.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
  }
}
</style>